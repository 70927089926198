import React, { useEffect, useState } from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Logo from '../assets/AboutLogo.png';
import axios from 'axios'; // Import Axios
import { Link } from 'react-router-dom';
import '../styles/Footer.css';

function Footer() {
  const [brandingData, setBrandingData] = useState({
    brand_name: '',
    address: '', // Default address
    contact_number: '', // Default contact number
    contact_number2: '', // Default secondary contact number
    email: '', // Default email
    facebook: '', // Default empty social media links
    instagram: '',
  });
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state

   // Function to format the contact number with spaces
   // Function to format the contact number with spaces
const formatPhoneNumber = (phoneNumber) => {
  // Remove non-digit characters
  const digits = phoneNumber.replace(/\D/g, '');

  // Format the number: 2nd and 3rd, 4th and 5th, and 7th and 8th
  return digits.replace(/(\d{2})(\d{1})(\d{2})(\d{1})(\d{2})(\d+)/, '$1 $2$3 $4$5 $6');
};

const toTitleCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const handleNavClick = () => {
  window.scrollTo(0, 0); // Scroll to the top of the page   
};
  // Fetch branding settings from the backend on component mount
  useEffect(() => {
    const fetchBrandingSettings = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/home`); // Axios GET request
        setBrandingData(response.data); // Store the fetched branding data in state
        setLoading(false); // Set loading to false after data is loaded
      } catch (error) {
        console.error('Error fetching branding settings:', error);
        setError(error.message); // Set error message if any error occurs
        setLoading(false); // Set loading to false if error occurs
      }
    };

    fetchBrandingSettings();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Display loading message while data is being fetched
  }

  if (error) {
    return <div>Error: {error}</div>; // Display error message if an error occurs
  }

  return (
    <div className='footer'>
      <div className='container'>
        <div>
        <div className='footerContent'>
          <div className='footerAbout'>
            <div>
            <h4>ABOUT</h4>
            </div>
            <div className='footerAboutSection'>
            <div className='logobg'>
              <img src={Logo} alt="Logo" />
            </div>
            <div>
            <p>
              At LEEFRAME, we are passionate about transforming wood into timeless pieces of art and functionality.
              Our dedication to craftsmanship and attention to detail ensures that every product we create stands as a testament to quality and durability.
            </p>
            </div>
            </div>
          </div>
          <div className='links'>
            <div>
            <h4>COMPANY</h4>
            </div>
            <div>
              <Link to="/"  onClick={handleNavClick}>Home</Link>
              <Link to="/products"  onClick={handleNavClick}>Products</Link>
              <Link to="/about"  onClick={handleNavClick}>About Us</Link>
              <Link to="/contact"  onClick={handleNavClick}>Contact</Link>
            </div>
          </div>
          <div className='contactDetails'>
            <h4>CONSTRUCTION OFFICE</h4>
            <div>
              <h4>ADDRESS:</h4>
              <p>{brandingData.address}</p>
            </div>
            <div>
              <h4>PHONE:</h4>
              <p>+{formatPhoneNumber(brandingData.contact_number)} / +{formatPhoneNumber(brandingData.contact_number2)}</p>
            </div>
            <div>
              <h4>EMAIL:</h4>
              <p>{brandingData.email}</p>
            </div>
          </div>
          <div className='workingHours'>
            <div>
            <h4>WORKING HOURS</h4>
            <div>
              <p>Monday to Friday 8am to 5pm.</p>
              <p>Saturday till 2pm.</p>
            </div>
            </div>
            <div className='social'>
              <h4>SOCIAL</h4>
              <div>
              {brandingData.facebook && (
                <a href={brandingData.facebook} target="_blank" rel="noopener noreferrer">
                  <FacebookIcon />
                </a>
              )}
              {brandingData.instagram && (
                <a href={brandingData.instagram} target="_blank" rel="noopener noreferrer">
                  <InstagramIcon />
                </a>
              )}
              </div>
            </div>
          </div>
        </div>
          <div className='copyright'>
            <p> {new Date().getFullYear()} Copyright &copy; All rights reserved by {toTitleCase(brandingData.brand_name)}.</p>
          </div>

          </div>
      </div>
    </div>
  );
}

export default Footer;
