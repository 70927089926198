import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import Axios for API calls
import { NavLink } from 'react-router-dom'; // Import NavLink
import ReorderIcon from '@mui/icons-material/Reorder';
import CloseIcon from '@mui/icons-material/Close';
import '@fontsource/oswald'
import '../styles/Navbar.css';

function Navbar() {
  const [openLinks, setOpenLinks] = useState(false);
  const [branding, setBranding] = useState({ brand_name: '', logo: '' }); // State to store branding details

  // Toggle the navbar
  const toggleNavbar = () => {
    setOpenLinks(!openLinks);
  };

  const handleNavLinkClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    toggleNavbar(); // Close the navbar
  };

  const handleNavClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page   
  };

  // Fetch branding details from the API
  useEffect(() => {
    const fetchBranding = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/home`); // Adjust the URL based on your backend
        setBranding(response.data); // Update state with API data
      } catch (error) {
        console.error('Error fetching branding details:', error);
      }
    };
    fetchBranding();
  }, []);

  return (
    <div className='navbar'>
       {openLinks && <div className="blurredBackground" onClick={toggleNavbar}></div>}
      <div className='leftSide' id={openLinks ? 'open' : 'close'}>
        <div className='logobg'>
        <NavLink to="/" exact>
            <img src={branding.logo} alt='' />
          </NavLink>
        </div>
        <div className='brandname'>
          <h3>{branding.brand_name}</h3>
        </div>
        <div className='hiddenLinks'>
          <button onClick={toggleNavbar} id="btnClose" title="Close">
            <CloseIcon />
          </button>
          <NavLink to='/' exact activeClassName='active' onClick={handleNavLinkClick}> HOME </NavLink>
          <NavLink to='/products' activeClassName='active' onClick={handleNavLinkClick}> PRODUCTS </NavLink>
          <NavLink to='/about' activeClassName='active' onClick={handleNavLinkClick}> ABOUT US </NavLink>
          <NavLink to='/contact' activeClassName='active' onClick={handleNavLinkClick}> CONTACT </NavLink>
        </div>
      </div>
      <div className='rightSide'>
        <NavLink to='/' exact activeClassName='active' onClick={handleNavClick}> HOME </NavLink>
        <NavLink to='/products' activeClassName='active' onClick={handleNavClick}> PRODUCTS </NavLink>
        <NavLink to='/about' activeClassName='active' onClick={handleNavClick}> ABOUT US </NavLink>
        <NavLink to='/contact' activeClassName='active' onClick={handleNavClick}> CONTACT </NavLink>
        <button onClick={toggleNavbar} id="btnToggle" title="Toggle">
          <ReorderIcon />
        </button>
      </div>
    </div>
  );
}

export default Navbar;



// import React, { useState } from 'react';
// import Logo from '../assets/Logo.png';
// import { NavLink } from 'react-router-dom'; // Import NavLink
// import ReorderIcon from '@mui/icons-material/Reorder';
// import CloseIcon from '@mui/icons-material/Close';
// import '../styles/Navbar.css';

// function Navbar() {

//   const [openLinks, setOpenLinks] = useState(false);

//   const toggleNavbar = () => {
//     setOpenLinks(!openLinks);
//   };

//   return (
//     <div className='navbar'>
//       <div className='leftSide' id={openLinks ? 'open' : 'close'}>
//         <div className='logobg'>
//           <img src={Logo} alt="Logo" />
//         </div>
//         <div className='brandname'>
//           <p>LEE FRAME (PRIVATE) LIMITED</p>
//         </div>
//         <div className='hiddenLinks'>
//           <button onClick={toggleNavbar} id="btnClose" title="Close">
//             <CloseIcon />
//           </button>
//           <NavLink to='/' exact activeClassName='active'> Home </NavLink>
//           <NavLink to='/products' activeClassName='active'> Products </NavLink>
//           <NavLink to='/about' activeClassName='active'> About Us </NavLink>
//           <NavLink to='/contact' activeClassName='active'> Contact </NavLink>
//         </div>
//       </div>
//       <div className='rightSide'>
//         <NavLink to='/' exact activeClassName='active'> Home </NavLink>
//         <NavLink to='/products' activeClassName='active'> Products </NavLink>
//         <NavLink to='/about' activeClassName='active'> About Us </NavLink>
//         <NavLink to='/contact' activeClassName='active'> Contact </NavLink>
//         <button onClick={toggleNavbar} id="btnToggle" title="Toggle">
//           <ReorderIcon />
//         </button>
//       </div>
//     </div>
//   )
// }

// export default Navbar;
